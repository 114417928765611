import moment from "moment";
import { maxLength, required } from "vuelidate/lib/validators";
import { ACTIVITY_STATUS_TYPES, ACTIVITY_CONSEQUENCE_TYPES } from "@/constants";
import { BaseModel } from "@/models/BaseModel";
import { ActivityCategory } from "@/models/ActivityCategory";
import { Answer } from "@/models/Answer";
import { Info } from "@/models/Info";
import { Protocol } from "@/models/Protocol";
import { Questionnaire } from "@/models/Questionnaire";
import { Person } from "@/models/Person";
import { ACTIVITY_CONTROLLED_STATUS_TYPES } from "@/constants";
import { Tag } from "@/models/Tag";

export class Activity extends BaseModel {
  constructor({
    id = null,
    creation_date = moment().format("YYYY-MM-DD"),
    name,
    description,
    deadline_date = moment().format("YYYY-MM-DD"),
    start_date = moment().format("YYYY-MM-DD"),
    done_date,
    link_type,
    progress,
    creator = null,
    responsible = null,
    controller = null,
    status = ACTIVITY_STATUS_TYPES.NOT_STARTED,
    is_yearly_activity = false,
    answer = null,
    category = null,
    info = null,
    protocol = null,
    link_activity = null,
    questionnaire = null,
    in_big_preset = false,
    in_small_preset = false,
    impact_real = ACTIVITY_CONSEQUENCE_TYPES.MEDIUM,
    impact_predicted = ACTIVITY_CONSEQUENCE_TYPES.MEDIUM,
    result,
    signature,
    send_reminders = true,
    activity_type,
    controlled_status = ACTIVITY_CONTROLLED_STATUS_TYPES.NA,
    controlled_date = moment().format("YYYY-MM-DD"),
    controlled_comment = null,
    controlled_by = null,
    tags = [],
    field1 = null, //standard (can it be used??)
    field2 = null, //private
    field3 = null, //department
    field4 = null, //priority
    field5 = null, //sign activity
    field6 = null, //Image signature
    field7 = null, //Area (operations,quality,enviroment,fire)
    field8 = null, //Visable to function
    field9 = null, //Controlled signature
    field10 = null,
    reminder_days_ahead = 7,
  }) {
    super();

    this.id = id;
    this.creation_date = creation_date;
    this.name = name;
    this.description = description;
    this.deadline_date = deadline_date;
    this.start_date = start_date;
    this.done_date = done_date;
    this.link_type = link_type;
    this.progress = progress;
    this.creator = creator ? new Person(creator) : null;
    this.responsible = responsible ? new Person(responsible) : null;
    this.controller = controller ? new Person(controller) : null;
    this.status = status;
    this.is_yearly_activity = is_yearly_activity;
    this.answer = answer ? new Answer(answer) : null;
    this.category = category ? new ActivityCategory(category) : null;
    this.info = info ? new Info(info) : null;
    this.protocol = protocol ? new Protocol(protocol) : null;
    this.link_activity = link_activity;
    this.questionnaire = questionnaire ? new Questionnaire(questionnaire) : null;
    this.in_big_preset = in_big_preset;
    this.in_small_preset = in_small_preset;
    this.impact_real = impact_real;
    this.impact_predicted = impact_predicted;
    this.result = result;
    this.signature = signature;
    this.send_reminders = send_reminders;
    this.activity_type = activity_type;
    this.controlled_status = controlled_status;
    this.controlled_date = controlled_date;
    this.controlled_comment = controlled_comment;
    this.controlled_by = controlled_by ? new Person(controlled_by) : null;
    this.tags = tags.map((x) => new Tag(x));
    this.field1 = field1;
    this.field2 = field2;
    this.field3 = field3;
    this.field4 = field4;
    this.field5 = field5;
    this.field6 = field6;
    this.field7 = field7;
    this.field8 = field8;
    this.field9 = field9;
    this.field10 = field10;
    this.reminder_days_ahead = reminder_days_ahead;
  }

  static get validations() {
    const validations = {
      name: {
        required,
        maxLength: maxLength(60)
      },
      deadline_date: {},
      start_date: {},
      description: {},
      creator: {},
      responsible: {}
    };

    return validations;
  }

  get isOverdue() {
    let today = moment();
    return moment(this.deadline_date).isBefore(today, "day");
  }

  get isNextWeek() {
    let today = moment();
    let nextWeek = moment().add(1, "week");
    let deadlineDate = moment(this.deadline_date);
    return deadlineDate.isSameOrBefore(nextWeek, "week") && deadlineDate.isSameOrAfter(today, "day");
  }

  isYear(year) {
    return new Date(this.deadline_date).getFullYear() === year;
  }

  isMonth(month) {
    return new Date(this.deadline_date).getMonth() === month;
  }

  get isDone() {
    return this.status == ACTIVITY_STATUS_TYPES.DONE;
  }

  get isWontDo() {
    return this.status == ACTIVITY_STATUS_TYPES.WONT_DO;
  }

  get isControlledApproved() {
    return this.controlled_status == ACTIVITY_CONTROLLED_STATUS_TYPES.APPROVED;
  }

  get isControlledNotApproved() {
    return this.controlled_status == ACTIVITY_CONTROLLED_STATUS_TYPES.NOT_APPROVED
  }
}

export default Activity;
