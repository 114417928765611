import BaseService from "./BaseService";
import { UserResponse, UsersResponse, UserRequest, PwGroupsResponse } from "./mappers/UserMapper";
import store from "@/store";
import { Capacitor } from "@capacitor/core";

class UserService extends BaseService {
  constructor() {
    super();
  }

  getMyProfile() {
    return this.get("my/profile/")
      .then((response) => response.data)
      .then((data) => UserResponse(data));
  }

  updateMyProfile(data) {
    return this.patch("my/profile/", data);
  }

  getUsersByCompany(companyId) {
    return this.get(`companies/${companyId}/users/`)
      .then((response) => response.data)
      .then((data) => UsersResponse(data));
  }

  getMyPwGroups() {
    return this.get(`my/pwgroups/`)
      .then((response) => response.data)
      .then((data) => PwGroupsResponse(data));
  }

  getMyAdminUsers() {
    return this.get(`my/adminusers/`)
      .then((response) => response.data)
      .then((data) => UsersResponse(data));
  }
  getUser(id) {
    return this.get(`users/${id}/`)
      .then((response) => response.data)
      .then((data) => UserResponse(data));
  }

  updateUser(id, data) {
    store.dispatch("app/setPersons", null);
    return this.patch(`users/${id}/`, UserRequest(data));
  }

  deleteUser(id) {
    store.dispatch("app/setPersons", null);
    return this.delete(`users/${id}/`);
  }

  syncAzureUsers() {
    return this.post(`syncazureusers/`)
  }

  getFortnoxUsers() {
    return this.get("fortnox/employees/")
      .then((response) => response.data)
      .then((data) => UsersResponse(data));
  }

  getFortnoxSickleave() {
    return this.get("fortnox/sickleave/").then((response) => response.data);
  }

  getRandomPassword() {
    return this.get("passwordgenerator/").then((response) => response.data);
  }

  inviteUser(email, password, first_name, last_name, pwgroups) {
    store.dispatch("app/setPersons", null);
    let registration = {
      email: email,
      username: email,
      first_name: first_name,
      last_name: last_name,
      pwgroups: [],
      password1: password,
      password2: password,
      invite: true
    };

    if (pwgroups) {
      for (var i = 0; i < pwgroups.length; i++) {
        registration.pwgroups.push(pwgroups[i].id);
      }
    }

    return new Promise((resolve, reject) => {
      return this.post("inviteuser/", registration)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getUsersByInfo(id) {
    return this.get(`infos/${id}/visibletousers/`)
      .then((response) => response.data)
      .then((data) => UsersResponse(data));
  }

  getEditorsByInfo(id) {
    return this.get(`infos/${id}/editableforusers/`)
      .then((response) => response.data)
      .then((data) => UsersResponse(data));
  }

  getUsersByProtocol(id) {
    return this.get(`protocols/${id}/visibletousers/`)
      .then((response) => response.data)
      .then((data) => UsersResponse(data));
  }

  activateFcmToken(data) {
    const type = Capacitor.getPlatform(); //web, ios, android;
    if (type != 'web') {
      const token_data = { registration_id: data, type: type };
      return this.post("devices", token_data);
    }

  }

  deactivateFcmToken(data) {
    const type = Capacitor.getPlatform();
    const token_data = { registration_id: data, type: type, active: false };
    if (type != 'web') {
      return this.post("devices", token_data);
    }
  }

}

export default new UserService();
