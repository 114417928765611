import Router from "../router/index";
import { Capacitor } from '@capacitor/core';
import { GenericOAuth2 } from '@capacitor-community/generic-oauth2';

const oauth2Options = {
  appId: "066ea2b5-de3f-4aee-ae35-efe55ccb1078",
  authorizationBaseUrl: 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize',
  accessTokenEndpoint: 'https://login.microsoftonline.com/common/oauth2/v2.0/token',
  responseType: "code",
  scope: 'openid offline_access api://09d67940-b360-4ea0-9935-c1d11ad2c965/access_as_user',
  redirectUrl: 'msauth://com.prewoe.cap/sDbOw1JVGt%2BTcu8Oql8KcNCyiC0%3D',
  additionalParameters: {
    prompt: 'select_account',
  },
  pkceEnabled: true,
  logsEnabled: false,
  web: { redirectUrl: process.env.VUE_APP_AUTH_REDIRECT_URI, },
  android: { redirectUrl: "msauth://com.prewoe.cap/sDbOw1JVGt%2BTcu8Oql8KcNCyiC0%3D", },
  ios: { pkceEnabled: true, redirectUrl: 'msauth.com.prewoe.cap://auth', },
};

const oauth2RefreshOptions = {
  accessTokenEndpoint: 'https://login.microsoftonline.com/common/oauth2/v2.0/token',
  appId: "066ea2b5-de3f-4aee-ae35-efe55ccb1078",
  grantType: "refresh_token",
  refreshToken: "",
};

class AzureAuthService {
  constructor() { }

  async login(redirectUrl) {
    try {
      const response = await GenericOAuth2.authenticate(oauth2Options);

      if (!response.access_token_response) {
        throw new Error("No access token received");
      }

      localStorage.setItem("token", response.access_token_response.access_token);
      localStorage.setItem("tokenExpiry", Date.now() + 3600 * 1000); // 1 hour
      localStorage.setItem("refreshToken", response.access_token_response.refresh_token);
      localStorage.setItem("azureUser", true);

      Router.push({ name: 'loginsuccess', query: { nextUrl: redirectUrl } });
    } catch (error) {
      console.error("OAuth2 login failed:", error);
    }
  }

  async logout() {
    try {
      await GenericOAuth2.logout({}, localStorage.getItem("token"));
      localStorage.clear(); 
    } catch (error) {
      console.error("OAuth logout failed:", error);
    }
  }

  async refreshToken() {
    const azureUser = localStorage.getItem("azureUser");
    const refreshToken = localStorage.getItem("refreshToken");
    const tokenExpiry = parseInt(localStorage.getItem("tokenExpiry"), 10);

    if (!azureUser || !refreshToken) {
      // Not an Azure user, no refresh needed
      return;
    }

    if (tokenExpiry && Date.now() < tokenExpiry) {
      // Access token still valid, no refresh needed
      return;
    }
    
    if (Capacitor.getPlatform() === 'android' || Capacitor.getPlatform() === 'ios') {
      try {
        oauth2RefreshOptions.refreshToken = refreshToken;
        const response = await GenericOAuth2.refreshToken(oauth2RefreshOptions);

        if (response.access_token) {
          localStorage.setItem("token", response.access_token);
          localStorage.setItem("refreshToken", response.refresh_token);
          localStorage.setItem("tokenExpiry", Date.now() + 3300 * 1000); // 50min
          localStorage.setItem("token", response.access_token);
        } else {
          console.error("OAuth2 Refresh Failed: No access token returned", response);
        }
      } catch (error) {
        console.error("OAuth2 Refresh Token failed:", error);
      }

      // If on Web manually refresh using fetch()
    } else {
      try {
        const response = await fetch("https://login.microsoftonline.com/common/oauth2/v2.0/token", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: new URLSearchParams({
            client_id: oauth2Options.appId,
            grant_type: "refresh_token",
            refresh_token: refreshToken,
            redirect_uri: oauth2Options.web.redirectUrl,
          }),
        });
        const data = await response.json();
        if (data.access_token) {
          localStorage.setItem("token", data.access_token);
          localStorage.setItem("refreshToken", data.refresh_token);
          localStorage.setItem("tokenExpiry", Date.now() + 3300 * 1000); // 50min
          localStorage.setItem("token", data.access_token);
        } else {
          console.error("Web OAuth2 Refresh Failed:", data);
        }
      } catch (error) {
        console.error("Web Token Refresh Error:", error);
      }
    }
  }

}
export default new AzureAuthService();
