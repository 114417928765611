import { maxLength, required } from "vuelidate/lib/validators";
import { BaseModel } from "@/models/BaseModel";
import { Company } from "@/models/Company";
import { FeedbackText } from "@/models/FeedbackText";
import { Question } from "@/models/Question";
import { QuestionnaireCategory } from "@/models/QuestionnaireCategory";
import { QUESTIONNAIRE_STATUS_TYPES } from "@/constants";
import moment from "moment";
import { Tag } from "@/models/Tag";

export class Questionnaire extends BaseModel {
  constructor({
    id = null,
    name,
    company = null,
    status = QUESTIONNAIRE_STATUS_TYPES.DRAFT,
    questions = [],
    question_count,
    feedback_texts = [],
    questionnaire_type = 1,
    version = 1,
    is_copy = false,
    is_onboarding,
    activate_by_default,
    allow_inactivation = true,
    identifier,
    category = null,
    field1,
    field2,
    field3,
    field4,  // Sub type
    field5,  //=2 for risklist
    creation_date,
    latest_change,
    visible_to = [],
    visible_to_user_groups = [],
    external_url,
    tags = [],
    step_size,
  }) {
    super();

    this.id = id;
    this.name = name;
    this.company = company ? new Company(company) : null;
    this.status = status;
    this.questions = questions.map((q) => new Question(q));
    this.question_count = question_count;
    this.feedback_texts = feedback_texts.map((ft) => new FeedbackText(ft));
    this.questionnaire_type = questionnaire_type;
    this.version = version;
    this.is_copy = is_copy;
    this.is_onboarding = is_onboarding;
    this.activate_by_default = activate_by_default;
    this.allow_inactivation = allow_inactivation;
    this.identifier = identifier;
    this.category = category ? new QuestionnaireCategory(category) : null;
    this.creation_date = moment(creation_date);
    this.latest_change = moment(latest_change);
    this.visible_to = visible_to;
    this.visible_to_user_groups = visible_to_user_groups;
    // Generic fields...
    this.field1 = field1;
    this.field2 = field2;
    this.field3 = field3;
    this.field4 = field4;
    this.field5 = field5;

    this.external_url = external_url;
    this.tags = tags.map((x) => new Tag(x));
    this.step_size = step_size;
  }

  static get validations() {
    const validations = {
      name: {
        required,
        maxLength: maxLength(250)
      },
      identifier: {
        maxLength: maxLength(64)
      }
    };

    return validations;
  }

  isCreatedByCompany(company) {
    return this.company && this.company.id === company.id;
  }
}

export default Questionnaire;
