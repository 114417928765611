import { Info } from "@/models/Info";
import PaginatedResponse from "@/models/PaginatedResponse";

export const InfoRequest = (argInfo) => {
  let info = argInfo.copy();

  if (!info.id) {
    delete info.id;
  }

  if (info.category) {
    info.category = info.category.id;
  }

  if (info.category2) {
    info.category2 = info.category2.id;
  }

  if (info.responsible) {
    info.responsible = info.responsible.id;
  }

  if (info.checkstatus_responsible) {
    info.checkstatus_responsible = info.checkstatus_responsible.id;
  }

  if (info.parent) {
    info.parent = info.parent.id;
  }

  if (info.parent_protocol) {
    info.parent_protocol = info.parent_protocol.id;
  }

  if (info.origin) {
    info.origin = info.origin.id;
  }

  if (info.previous_version) {
    info.previous_version = info.previous_version.id;
  }

  if (info.next_version) {
    info.next_version = info.next_version.id;
  }

  if (info.department) {
    info.department = info.department.id;
  }

  // Avoid sending empty string to backend for date values
  if (info.deadline_date === "") {
    info.deadline_date = null;
  }

  if (info.siblings) {
    for (var i = 0; i < info.siblings.length; i++) {
      info.siblings[i] = info.siblings[i].id;
    }
  }

  if (info.tags) {
    for (var i = 0; i < info.tags.length; i++) {
      info.tags[i] = info.tags[i].id;
    }
  }

  if (info.esrstags) {
    for (var i = 0; i < info.esrstags.length; i++) {
      info.esrstags[i] = info.esrstags[i].id;
    }
  }

  info.activities.forEach((a) => {
    if (!a.id) {
      delete a.id;
    }

    if (a.category) {
      a.category = a.category.id;
    }

    if (a.responsible) {
      a.responsible = a.responsible.id;
    }

    if (a.controller) {
      a.controller = a.controller.id;
    }

    if (a.questionnaire) {
      a.questionnaire = a.questionnaire.id;
    }
  });


  info.checkstatus_activities.forEach((a) => {
    if (!a.id) {
      delete a.id;
    }

    if (a.category) {
      a.category = a.category.id;
    }

    if (a.responsible) {
      a.responsible = a.responsible.id;
    }

    if (a.controller) {
      a.controller = a.controller.id;
    }

    if (a.questionnaire) {
      a.questionnaire = a.questionnaire.id;
    }
  });

  for (var i = 0; i < info.visible_to_departments.length; i++) {
    info.visible_to_departments[i] = info.visible_to_departments[i].id;
  }

  for (var i = 0; i < info.visible_to_user_groups.length; i++) {
    info.visible_to_user_groups[i] = info.visible_to_user_groups[i].id;
  }

  for (var i = 0; i < info.editable_for_departments.length; i++) {
    info.editable_for_departments[i] = info.editable_for_departments[i].id;
  }

  for (var i = 0; i < info.editable_for_user_groups.length; i++) {
    info.editable_for_user_groups[i] = info.editable_for_user_groups[i].id;
  }

  /* Remove the translation fields if they should not be set explicitly
     If not removed, the content_sv field will overwrite the content field. */
  if (info.setTranslationFieldsExplicitly != true) {
    delete info.name_sv;
    delete info.name_en;
    delete info.content_sv;
    delete info.content_en;
  }

  return info;
};

export const InfoResponse = (data) => {
  return new Info(data);
};

export const InfosResponse = (data) => {
  return data.map((p) => InfoResponse(p));
};

export const PaginatedInfosResponse = (data) => {
  let response = new PaginatedResponse(data);
  response.results = response.results.map((i) => InfoResponse(i));
  return response;
};
