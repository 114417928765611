import AzureAuthService from "@/services/AzureAuthService";
import axios from "axios";

export const RequestInterceptor = async (req) => {

    // Don't set token to s3-bucket urls.
    const deniedUrls = [
        'https://prewoe-files-',
      ];
    const shouldDeny = deniedUrls.some((url) => req.url?.includes(url));
    if(shouldDeny){
        return req;
    }

    await AzureAuthService.refreshToken();

    let token = localStorage.getItem("token");
    if (token) {
        req.headers.Authorization = "Token " + token;
        axios.defaults.headers.common["Authorization"] = "Token " + token;
    }
    
    return req;
};

export default RequestInterceptor;
