import "@fortawesome/fontawesome-free/css/all.css";
import axios from "axios";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import Vue from "vue";
import VueTouch from "vue-touch";
import Vuelidate from "vuelidate";
import App from "./App.vue";
import "./components";
import "./filters";
import "./mixins";
import i18n from "./helpers/language";
import svgSpriteLoader from "./helpers/svg-sprite-loader";
import router from "./router";
import "./sass/theme.scss";
import store from "./store";
import InterceptorSetup from "@/services/interceptors/InterceptorSetup";
import VueScrollTo from "vue-scrollto";
import VueAnalytics from "vue-analytics";
import Chart from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Vue2Filters from "vue2-filters";
import VueSignaturePad from "vue-signature-pad";
import VueCryptojs from "vue-cryptojs";
import IdleVue from "idle-vue";
import { ActionPerformed, PushNotificationSchema, PushNotifications, Token } from "@capacitor/push-notifications";
import { App as CapacitorApp } from '@capacitor/app';
import { setWebviewBounce, setBackForwardNavigationGestures } from "capacitor-plugin-ios-webview-configurator";
import { Capacitor } from "@capacitor/core";


Chart.plugins.unregister(ChartDataLabels);

const __svg__ = {
  path: "./assets/images/icons/*.svg",
  name: "assets/images/[hash].sprite.svg"
};
svgSpriteLoader(__svg__.filename);

InterceptorSetup();

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueTouch);
Vue.use(Vuelidate);
Vue.use(VueScrollTo);
Vue.use(Vue2Filters);
Vue.use(VueSignaturePad);
Vue.use(VueCryptojs);

const eventsHub = new Vue();
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 1000 * 60 * 45,
  startAtIdle: false
});

Vue.config.productionTip = false;

const isProd = process.env.NODE_ENV === "production";
Vue.use(VueAnalytics, {
  id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID,
  router,
  autoTracking: {
    exception: true
  },
  debug: {
    enabled: !isProd,
    sendHitTask: isProd
  }
});

let token = localStorage.getItem("token");
if (token) {
  axios.defaults.headers.common["Authorization"] = "Token " + token;
}

if(Capacitor.isNativePlatform())
{
  // Request permission to use push notifications
  // iOS will prompt user and return if they granted permission or not
  // Android will just grant without prompting
  PushNotifications.requestPermissions().then((result) => {
    if (result.receive === "granted") {
      // Register with Apple / Google to receive push via APNS/FCM
      PushNotifications.register();
    } else {
      // Show some error
    }
  });

  // On success, we should be able to receive notifications
  PushNotifications.addListener("registration", (token) => {
    store.dispatch("auth/setDeviceToken", token.value);
    //console.log("Push registration success, token: " + token.value);
  });

  // Some issue with our setup and push will not work
  PushNotifications.addListener("registrationError", (error) => {
    //console.log("Error on registration: " + JSON.stringify(error));
  });

  // Show us the notification payload if the app is open on our device
  PushNotifications.addListener("pushNotificationReceived", (notification) => {
    //console.log("Push received: " + JSON.stringify(notification));
  });

  // Method called when tapping on a notification
  PushNotifications.addListener("pushNotificationActionPerformed", (notification) => {
    //console.log("Push action performed: " + JSON.stringify(notification));
  });

  // Handle back button
  CapacitorApp.addListener('backButton', ({ canGoBack }) => {
    if (!canGoBack) {
      CapacitorApp.exitApp();
    } else {
      window.history.back();
    }
  });
}

//setWebviewBounce(true);
setBackForwardNavigationGestures(true);

// Setup deep linking
CapacitorApp.addListener('URLOpenListenerEvent', (event) => {
  // Example url: https://beerswift.app/tabs/tabs2
  // slug = /tabs/tabs2
  const slug = event.url.split('.app').pop();

    // List routes to exlude
    const excludedRoutes = ['/public', '/whistleblower'];

    if (excludedRoutes.includes(path)) {
      // Cancel and open browser instead
      window.location.href = event.url;
      return;
    }

  // We only push to the route if there is a slug present
  if (slug) {
    router.push({
      path: slug,
    });
  }
});

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App)
}).$mount("#app");
