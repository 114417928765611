import axios from "axios";

class BaseService {
  constructor() {
    axios.defaults.baseURL = process.env.VUE_APP_API_URL;
  }

  post(url, data = null) {
    return axios.post(url, data);
  }

  get(url, options = {}) {
    return axios.get(url, options);
  }

  delete(url) {
    return axios.delete(url);
  }

  patch(url, data) {
    return axios.patch(url, data);
  }

  put(url, data) {
    return axios.put(url, data);
  }

  getOrderedColumnName(column, desc) {
    if (desc) {
      if (Array.isArray(column)) {
        column.forEach((item, index, array) => {
          array[index] = "-" + item;
        });
      }
      else {
        column = "-" + column; // Descending order in backend is the field name with a minus sign in front
      }
    }
    return column;
  }
}

export default BaseService;
