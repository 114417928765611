import _ from "lodash";
import {
  ACTIVITY_STATUS_TYPES,
  ACTIVITY_CONTROLLED_STATUS_TYPES,
  COMPETENCE_CONSEQUENCE_TYPES,
  ACTIVITY_CONSEQUENCE_TYPES,
  CUSTOMER_SUPPLIER_PRIO_TYPES,
  HIDDEN_QUESTIONNAIRE_TYPES,
  INFO_STATUS_TYPES,
  DATAPOINT_STATUS_TYPES,
  ESRS_DATA_AVAILIBILITY_TYPES,
  INFO_TYPES,
  INFO_GOAL_FOLLOWUP_TYPES,
  INFO_EMPLOYEE_FOLLOWUP_TYPES,
  HELP_STATUS_TYPES,
  OPPORTUNITY_TYPES,
  OPPORTUNITY_CONSEQUENCE_TYPES,
  OPPORTUNITY_PROBABILITY_TYPES,
  OPPORTUNITY_STATUS_TYPES,
  QUESTIONNAIRE_TYPES,
  QUESTIONNAIRE_STATUS_TYPES,
  QUESTION_INPUT_TYPES,
  REFRESHER_CONSEQUENCE_TYPES,
  THREAT_ACTION_ACTION_TYPES,
  THREAT_PROBABILITY_TYPES,
  THREAT_CONSEQUENCE_TYPES,
  THREAT_STATUS_TYPES,
  THREAT_TYPES,
  VULNERABLE_LEVEL_TYPES,
  CLAIM_TYPES,
  CLAIM_STATUS_TYPES,
  CLAIM_IMPACT_TYPES,
  DIGITAL_CLAIM_TYPES,
  DIGITAL_CLAIM_IMPACT_TYPES,
  ROPA_STATUS_TYPES,
  SURVEY_STATUS_TYPES,
  DEVIATION_TYPES,
  IDEA_TYPES,
  ROPA_TYPES1,
  ROPA_TYPES2,
  ROPA_TYPES21,
  ROPA_TYPES22,
  ROPA_TYPES23,
  ROPA_TYPES24,
  ROPA_TYPES25,
  ROPA_IMPACT_TYPES,
  CRISIS_TYPES,
  CRISIS_IMPACT_TYPES,
  FIRE_TYPES,
  FIRE_IMPACT_TYPES,
  DASHBOARD_WIDGET_TYPES,
  DASHBOARD_WIDGET_POS,
  DASHBOARD_WIDGET_FUNCTIONS,
  PROCESS_MAP_TYPES,
  WASTE_TYPES,
  VALUE_CHAIN_TYPES,
  VALUE_CHAIN_SHORT_TYPES,
  TIMEFRAME_TYPES,
  ASPECT_TYPES,
  QBIC_TYPES,
  ESRS_CATEGORY_TYPES,
  ESRS_EXTENDED_CATEGORY_TYPES,
  EQUIPMENT_TYPES,
  SIDEBAR_TYPES,
  SHIPPING_TYPES,
  REAL_ESTATE_TYPES,
  COMMUTING_TYPES,
  BUSINESS_TRAVEL_TYPES,
  SUPPLIER_COMPANY_TYPES,
  MILESTONE_STATUS_TYPES,
  QUESTION_REPORT_TYPES,
  BUSINESS_STATUS_TYPES
} from "@/constants";
import i18n from "@/helpers/language";

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {},
  getters: {
    languages() {
      return [
        {
          value: "sv",
          text: i18n.t("types.languages.sv")
        },
        {
          value: "en",
          text: i18n.t("types.languages.en")
        },
        {
          value: "no",
          text: i18n.t("types.languages.no")
        },
        {
          value: "da",
          text: i18n.t("types.languages.da")
        },
        {
          value: "fi",
          text: i18n.t("types.languages.fi")
        },
        {
          value: "de",
          text: i18n.t("types.languages.de")
        }
      ];
    },
    activityStatusTypes() {
      let types = [];
      for (let [key, val] of Object.entries(ACTIVITY_STATUS_TYPES)) {
        types.push({
          value: Number(val),
          text: i18n.t("types.activityStatusTypes." + val)
        });
      }
      return types;
    },
    activityControlledStatusTypes() {
      let types = [];
      for (let [key, val] of Object.entries(ACTIVITY_CONTROLLED_STATUS_TYPES)) {
        types.push({
          value: Number(val),
          text: i18n.t("types.activityControlledStatusTypes." + val)
        });
      }
      return types;
    },
    activitySignTypes() {
      let types = [];
      for (let [key, val] of Object.entries(ACTIVITY_STATUS_TYPES)) {
        types.push({
          value: Number(val),
          text: i18n.t("types.activitySignTypes." + val)
        });
      }
      return types;
    },
    infoStatusTypes() {
      let types = [];
      for (let [key, val] of Object.entries(INFO_STATUS_TYPES)) {
        types.push({
          value: Number(val),
          text: i18n.t("types.infoStatusTypes." + val)
        });
      }
      return types;
    },
    crmStatusTypes() {
      let types = [];
      for (let [key, val] of Object.entries(INFO_STATUS_TYPES)) {
        types.push({
          value: Number(val),
          text: i18n.t("types.crmStatusTypes." + val)
        });
      }
      return types;
    },
    datapointStatusTypes() {
      let types = [];
      for (let [key, val] of Object.entries(DATAPOINT_STATUS_TYPES)) {
        types.push({
          value: Number(val),
          text: i18n.t("types.datapointStatusTypes." + val)
        });
      }
      return types;
    },
    esrsDataAvailabilityTypes() {
      let types = [];
      for (let [key, val] of Object.entries(ESRS_DATA_AVAILIBILITY_TYPES)) {
        types.push({
          value: Number(val),
          text: i18n.t("types.esrsDataAvailabilityTypes." + val)
        });
      }
      return types;
    },
    infoTypes() {
      let types = [];
      for (let [key, val] of Object.entries(INFO_TYPES)) {
        types.push({
          value: Number(val),
          text: i18n.tc("types.infoTypes." + val)
        });
      }
      return types;
    },
    infoGoalFollowUpTypes() {
      let types = [];
      for (let [key, val] of Object.entries(INFO_GOAL_FOLLOWUP_TYPES)) {
        types.push({
          value: Number(val),
          text: i18n.t("types.infoGoalFollowUpTypes." + val)
        });
      }
      return types;
    },
    infoEmployeeFollowUpTypes() {
      let types = [];
      for (let [key, val] of Object.entries(INFO_EMPLOYEE_FOLLOWUP_TYPES)) {
        types.push({
          value: Number(val),
          text: i18n.t("types.infoEmployeeFollowUpTypes." + val)
        });
      }
      return types;
    },
    helpStatusTypes() {
      let types = [];
      for (let [key, val] of Object.entries(HELP_STATUS_TYPES)) {
        types.push({
          value: Number(val),
          text: i18n.tc("types.helpStatusTypes." + val)
        });
      }
      return types;
    },
    questionnaireTypes() {
      let questionnaireTypes = [];
      for (let [key, val] of Object.entries(QUESTIONNAIRE_TYPES)) {
        questionnaireTypes.push({
          value: val,
          text: i18n.t("types.questionnaireTypes." + val)
        });
      }

      questionnaireTypes = _.orderBy(questionnaireTypes, ["text"], ["asc"]);

      return questionnaireTypes;
    },
    questionnaireTypesWithoutHidden() {
      let questionnaireTypes = [];
      for (let [key, val] of Object.entries(QUESTIONNAIRE_TYPES)) {
        questionnaireTypes.push({
          value: val,
          text: i18n.t("types.questionnaireTypes." + val)
        });
      }

      questionnaireTypes = _.orderBy(questionnaireTypes, ["text"], ["asc"]);

      questionnaireTypes = questionnaireTypes.filter((qt) => !HIDDEN_QUESTIONNAIRE_TYPES.includes(qt.value));

      return questionnaireTypes;
    },
    questionnaireStatusTypes() {
      let types = [];
      for (let [key, val] of Object.entries(QUESTIONNAIRE_STATUS_TYPES)) {
        types.push({
          value: Number(val),
          text: i18n.t("types.questionnaireStatusTypes." + val)
        });
      }
      return types;
    },
    questionInputTypes() {
      let questionInputTypes = [];
      for (let [key, val] of Object.entries(QUESTION_INPUT_TYPES)) {
        questionInputTypes.push({
          value: val,
          text: i18n.t("types.questionInputTypes." + val)
        });
      }
      return questionInputTypes;
    },
    questionReportTypes() {
      let questionReportTypes = [];
      for (let [key, val] of Object.entries(QUESTION_REPORT_TYPES)) {
        questionReportTypes.push({
          value: val,
          text: i18n.t("types.questionReportTypes." + val)
        });
      }
      return questionReportTypes;
    },
    competenceConsequenceTypes() {
      let competenceConsequenceTypes = [];
      for (let [key, val] of Object.entries(COMPETENCE_CONSEQUENCE_TYPES)) {
        competenceConsequenceTypes.push({
          value: val,
          text: i18n.t("types.competenceConsequenceTypes." + val)
        });
      }
      return competenceConsequenceTypes;
    },
    competenceConsequenceType: (state, getters) => (value) => {
      return getters.competenceConsequenceTypes.find((c) => c.value === value);
    },
    activityConsequenceTypes() {
      let activityConsequenceTypes = [];
      for (let [key, val] of Object.entries(ACTIVITY_CONSEQUENCE_TYPES)) {
        activityConsequenceTypes.push({
          value: val,
          text: i18n.t("types.activityConsequenceTypes." + val)
        });
      }
      return activityConsequenceTypes;
    },
    qualityCustomerSupplierPrioTypes() {
      let types = [];
      for (let [key, val] of Object.entries(CUSTOMER_SUPPLIER_PRIO_TYPES)) {
        types.push({
          value: val,
          text: i18n.t("types.customerSupplierPrioTypes." + val)
        });
      }
      return types;
    },
    supplierCompanyTypes() {
      let supplierCompanyTypes = [];
      for (let [key, val] of Object.entries(SUPPLIER_COMPANY_TYPES)) {
        supplierCompanyTypes.push({
          value: val,
          text: i18n.t("types.supplierCompanyTypes." + val)
        });
      }
      return supplierCompanyTypes;
    },
    activityConsequenceType: (state, getters) => (value) => {
      return getters.activityConsequenceTypes.find((c) => c.value === value);
    },
    opportunityTypes() {
      let types = [];
      for (let [key, val] of Object.entries(OPPORTUNITY_TYPES)) {
        types.push({
          value: Number(val),
          text: i18n.t("types.opportunityTypes." + val)
        });
      }
      return types;
    },
    opportunityConsequenceTypes() {
      let types = [];
      for (let [key, val] of Object.entries(OPPORTUNITY_CONSEQUENCE_TYPES)) {
        types.push({
          value: Number(val),
          text: i18n.t("types.opportunityConsequenceTypes." + val)
        });
      }
      return types;
    },
    opportunityProbabilityTypes() {
      let types = [];
      for (let [key, val] of Object.entries(OPPORTUNITY_PROBABILITY_TYPES)) {
        types.push({
          value: Number(val),
          text: i18n.t("types.opportunityProbabilityTypes." + val)
        });
      }
      return types;
    },
    opportunityStatusTypes() {
      let types = [];
      for (let [key, val] of Object.entries(OPPORTUNITY_STATUS_TYPES)) {
        types.push({
          value: Number(val),
          text: i18n.t("types.opportunityStatusTypes." + val)
        });
      }
      return types;
    },
    refresherConsequenceTypes() {
      let types = [];
      for (let [key, val] of Object.entries(REFRESHER_CONSEQUENCE_TYPES)) {
        types.push({
          value: val,
          text: i18n.t("types.refresherConsequenceTypes." + val)
        });
      }
      return types;
    },
    refresherConsequenceType: (state, getters) => (value) => {
      return getters.refresherConsequenceTypes.find((c) => c.value === value);
    },
    threatTypes() {
      let types = [];
      for (let [key, val] of Object.entries(THREAT_TYPES)) {
        types.push({
          value: Number(val),
          text: i18n.t("types.threatTypes." + val)
        });
      }
      return types;
    },
    threatStatusTypes() {
      let types = [];
      for (let [key, val] of Object.entries(THREAT_STATUS_TYPES)) {
        types.push({
          value: Number(val),
          text: i18n.t("types.threatStatusTypes." + val)
        });
      }
      return types;
    },
    threatConsequenceTypes() {
      let types = [];
      for (let [key, val] of Object.entries(THREAT_CONSEQUENCE_TYPES)) {
        types.push({
          value: Number(val),
          text: i18n.t("types.threatConsequenceTypes." + val)
        });
      }
      return types;
    },
    threatProbabilityTypes() {
      let types = [];
      for (let [key, val] of Object.entries(THREAT_PROBABILITY_TYPES)) {
        types.push({
          value: Number(val),
          text: i18n.t("types.threatProbabilityTypes." + val)
        });
      }
      return types;
    },
    threatActionActionTypes() {
      let types = [];
      for (let [key, val] of Object.entries(THREAT_ACTION_ACTION_TYPES)) {
        types.push({
          value: Number(val),
          text: i18n.t("types.threatActionActionTypes." + val)
        });
      }
      return types;
    },
    vulnerableLevelTypes() {
      let types = [];
      for (let [key, val] of Object.entries(VULNERABLE_LEVEL_TYPES)) {
        types.push({
          value: Number(val),
          text: i18n.t("types.vulnerableLevelTypes." + val)
        });
      }
      return types;
    },
    businessStatusTypes() {
      let types = [];
      for (let [key, val] of Object.entries(BUSINESS_STATUS_TYPES)) {
        types.push({
          value: Number(val),
          text: i18n.t("types.businessStatusTypes." + val)
        });
      }
      return types;
    },
    claimTypes() {
      let types = [];
      for (let [key, val] of Object.entries(CLAIM_TYPES)) {
        types.push({
          value: Number(val),
          text: i18n.t("types.claimTypes." + val)
        });
      }
      return types;
    },
    claimStatusTypes() {
      let types = [];
      for (let [key, val] of Object.entries(CLAIM_STATUS_TYPES)) {
        types.push({
          value: Number(val),
          text: i18n.t("types.claimStatusTypes." + val)
        });
      }
      return types;
    },
    claimImpactTypes() {
      let types = [];
      for (let [key, val] of Object.entries(CLAIM_IMPACT_TYPES)) {
        types.push({
          value: Number(val),
          text: i18n.t("types.claimImpactTypes." + val)
        });
      }
      return types;
    },
    digitalClaimTypes() {
      let types = [];
      for (let [key, val] of Object.entries(DIGITAL_CLAIM_TYPES)) {
        types.push({
          value: Number(val),
          text: i18n.t("types.digitalClaimTypes." + val)
        });
      }
      return types;
    },
    digitalClaimImpactTypes() {
      let types = [];
      for (let [key, val] of Object.entries(DIGITAL_CLAIM_IMPACT_TYPES)) {
        types.push({
          value: Number(val),
          text: i18n.t("types.digitalClaimImpactTypes." + val)
        });
      }
      return types;
    },
    crisisTypes() {
      let types = [];
      for (let [key, val] of Object.entries(CRISIS_TYPES)) {
        types.push({
          value: Number(val),
          text: i18n.t("types.crisisTypes." + val)
        });
      }
      return types;
    },
    ropaStatusTypes() {
      let types = [];
      for (let [key, val] of Object.entries(ROPA_STATUS_TYPES)) {
        types.push({
          value: Number(val),
          text: i18n.t("types.ropaStatusTypes." + val)
        });
      }
      return types;
    },
    ropaTypes1() {
      let types = [];
      for (let [key, val] of Object.entries(ROPA_TYPES1)) {
        types.push({
          value: Number(val),
          text: i18n.t("types.ropaTypes1." + val)
        });
      }
      return types;
    },
    ropaTypes2() {
      let types = [];
      for (let [key, val] of Object.entries(ROPA_TYPES2)) {
        types.push({
          value: Number(val),
          text: i18n.t("types.ropaTypes2." + val)
        });
      }
      return types;
    },
    ropaTypes21() {
      let types = [];
      for (let [key, val] of Object.entries(ROPA_TYPES21)) {
        types.push({
          value: Number(val),
          text: i18n.t("types.ropaTypes21." + val)
        });
      }
      return types;
    },
    ropaTypes22() {
      let types = [];
      for (let [key, val] of Object.entries(ROPA_TYPES22)) {
        types.push({
          value: Number(val),
          text: i18n.t("types.ropaTypes22." + val)
        });
      }
      return types;
    },
    ropaTypes23() {
      let types = [];
      for (let [key, val] of Object.entries(ROPA_TYPES23)) {
        types.push({
          value: Number(val),
          text: i18n.t("types.ropaTypes23." + val)
        });
      }
      return types;
    },
    ropaTypes24() {
      let types = [];
      for (let [key, val] of Object.entries(ROPA_TYPES24)) {
        types.push({
          value: Number(val),
          text: i18n.t("types.ropaTypes24." + val)
        });
      }
      return types;
    },
    ropaTypes25() {
      let types = [];
      for (let [key, val] of Object.entries(ROPA_TYPES25)) {
        types.push({
          value: Number(val),
          text: i18n.t("types.ropaTypes25." + val)
        });
      }
      return types;
    },
    ropaImpactTypes() {
      let types = [];
      for (let [key, val] of Object.entries(ROPA_IMPACT_TYPES)) {
        types.push({
          value: Number(val),
          text: i18n.t("types.ropaImpactTypes." + val)
        });
      }
      return types;
    },
    crisisImpactTypes() {
      let types = [];
      for (let [key, val] of Object.entries(CRISIS_IMPACT_TYPES)) {
        types.push({
          value: Number(val),
          text: i18n.t("types.crisisImpactTypes." + val)
        });
      }
      return types;
    },

    fireTypes() {
      let types = [];
      for (let [key, val] of Object.entries(FIRE_TYPES)) {
        types.push({
          value: Number(val),
          text: i18n.t("types.fireTypes." + val)
        });
      }
      return types;
    },
    fireImpactTypes() {
      let types = [];
      for (let [key, val] of Object.entries(FIRE_IMPACT_TYPES)) {
        types.push({
          value: Number(val),
          text: i18n.t("types.fireImpactTypes." + val)
        });
      }
      return types;
    },
    infoTypes() {
      let types = [];
      for (let [key, val] of Object.entries(INFO_TYPES)) {
        types.push({
          value: Number(val),
          text: i18n.tc("types.infoTypes." + val)
        });
      }
      return types;
    },
    surveyStatusTypes() {
      let types = [];
      for (let [key, val] of Object.entries(SURVEY_STATUS_TYPES)) {
        types.push({
          value: Number(val),
          text: i18n.t("types.surveyStatusTypes." + val)
        });
      }
      return types;
    },
    wasteTypes() {
      let types = [];
      for (let [key, val] of Object.entries(WASTE_TYPES)) {
        types.push({
          value: Number(val),
          text: i18n.t("types.wasteTypes." + val)
        });
      }
      return types;
    },
    equipmentTypes() {
      let types = [];
      for (let [key, val] of Object.entries(EQUIPMENT_TYPES)) {
        types.push({
          value: Number(val),
          text: i18n.t("types.equipmentTypes." + val)
        });
      }
      return types;
    },
    sidebarTypes() {
      let types = [];
      for (let [key, val] of Object.entries(SIDEBAR_TYPES)) {
        types.push({
          value: Number(val),
          text: i18n.t("types.sidebarTypes." + val)
        });
      }
      return types;
    },



    businessTravelTypes() {
      let types = [];
      for (let [key, val] of Object.entries(BUSINESS_TRAVEL_TYPES)) {
        types.push({
          value: Number(val),
          text: i18n.t("types.businessTravelTypes." + val)
        });
      }
      return types;
    },
    commutingTypes() {
      let types = [];
      for (let [key, val] of Object.entries(COMMUTING_TYPES)) {
        types.push({
          value: Number(val),
          text: i18n.t("types.commutingTypes." + val)
        });
      }
      return types;
    },
    realEstateTypes() {
      let types = [];
      for (let [key, val] of Object.entries(REAL_ESTATE_TYPES)) {
        types.push({
          value: Number(val),
          text: i18n.t("types.realEstateTypes." + val)
        });
      }
      return types;
    },
    shippingTypes() {
      let types = [];
      for (let [key, val] of Object.entries(SHIPPING_TYPES)) {
        types.push({
          value: Number(val),
          text: i18n.t("types.shippingTypes." + val)
        });
      }
      return types;
    },
    milestoneStatusTypes() {
      let types = [];
      for (let [key, val] of Object.entries(MILESTONE_STATUS_TYPES)) {
        types.push({
          value: Number(val),
          text: i18n.t("types.milestoneStatusTypes." + val)
        });
      }
      return types;
    },
    deviationTypes() {
      let types = [];
      for (let [key, val] of Object.entries(DEVIATION_TYPES)) {
        types.push({
          value: Number(val),
          text: i18n.t("types.deviationTypes." + val)
        });
      }
      return types;
    },
    ideaTypes() {
      let types = [];
      for (let [key, val] of Object.entries(IDEA_TYPES)) {
        types.push({
          value: Number(val),
          text: i18n.t("types.ideaTypes." + val)
        });
      }
      return types;
    },
    dashboardWidgetTypes() {
      let types = [];
      for (let [key, val] of Object.entries(DASHBOARD_WIDGET_TYPES)) {
        types.push({
          value: Number(val),
          text: i18n.t("types.dashboardWidgetTypes." + val)
        });
      }
      return types;
    },
    dashboardWidgetPos() {
      let types = [];
      for (let [key, val] of Object.entries(DASHBOARD_WIDGET_POS)) {
        types.push({
          value: Number(val),
          text: i18n.t("types.dashboardWidgetPos." + val)
        });
      }
      return types;
    },
    dashboardWidgetFunctions() {
      let types = [];
      for (let [key, val] of Object.entries(DASHBOARD_WIDGET_FUNCTIONS)) {
        types.push({
          value: Number(val),
          text: i18n.t("types.dashboardWidgetFunctions." + val)
        });
      }
      return types;
    },
    prossessMapTypes() {
      let types = [];
      for (let [key, val] of Object.entries(PROCESS_MAP_TYPES)) {
        types.push({
          value: Number(val),
          text: i18n.t("types.prossessMapTypes." + val)
        });
      }
      return types;
    },
    valueChainTypes() {
      let types = [];
      for (let [key, val] of Object.entries(VALUE_CHAIN_TYPES)) {
        types.push({
          value: Number(val),
          text: i18n.t("types.valueChainTypes." + val)
        });
      }
      return types;
    },
    valueChainShortTypes() {
      let types = [];
      for (let [key, val] of Object.entries(VALUE_CHAIN_SHORT_TYPES)) {
        types.push({
          value: Number(val),
          text: i18n.t("types.valueChainShortTypes." + val)
        });
      }
      return types;
    },
    timeframeTypes() {
      let types = [];
      for (let [key, val] of Object.entries(TIMEFRAME_TYPES)) {
        types.push({
          value: Number(val),
          text: i18n.t("types.timeframeTypes." + val)
        });
      }
      return types;
    },
    aspectTypes() {
      let types = [];
      for (let [key, val] of Object.entries(ASPECT_TYPES)) {
        types.push({
          value: Number(val),
          text: i18n.t("types.aspectTypes." + val)
        });
      }
      return types;
    },
    qbicTypes() {
      let types = [];
      for (let [key, val] of Object.entries(QBIC_TYPES)) {
        types.push({
          value: Number(val),
          text: i18n.t("types.qbicTypes." + val)
        });
      }
      return types;
    },
    esrsCategoryTypes() {
      let types = [];
      for (let [key, val] of Object.entries(ESRS_CATEGORY_TYPES)) {
        types.push({
          value: Number(val),
          text: i18n.t("esrsCategoryTypes." + val)
        });
      }
      return types;
    },
    esrsExtendedCategoryTypes2() {
      let types = [];
      for (let [key, val] of Object.entries(ESRS_EXTENDED_CATEGORY_TYPES)) {
        types.push({
          value: Number(val),
          text: i18n.t("esrsCategoryTypesExtended." + val)
        });
      }
      return types;
    },
    esrsExtendedCategoryTypes() {
      let types = [];
      for (let [key, val] of Object.entries(ESRS_EXTENDED_CATEGORY_TYPES)) {
        types.push({
          value: Number(val),
          text: i18n.t("esrsCategoryTypesExtended" + val)
        });
      }
      return types;
    },
    esrsExtendedCategoryTypesFull() {
      let types = [[], [], [], [], [], [], [], [], [], [], []];

      const categoryCount = 11;

      for (let i = 0; i < categoryCount; i++) {
        let categoryKey = `esrsCategoryTypesExtended${i}`;
        let category = i18n.t(categoryKey);
        for (let [key, val] of Object.entries(category)) {
          types[i].push({
            value: key,
            text: val
          });
        }
      }
      return types;
    },
  }
};
