import { render, staticRenderFns } from "./PwButton.vue?vue&type=template&id=342f3b6d&scoped=true"
import script from "./PwButton.vue?vue&type=script&lang=js"
export * from "./PwButton.vue?vue&type=script&lang=js"
import style0 from "./PwButton.vue?vue&type=style&index=0&id=342f3b6d&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "342f3b6d",
  null
  
)

export default component.exports