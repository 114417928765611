import { BaseModel } from "@/models/BaseModel";
import { Company } from "@/models/Company";
import { PwGroup } from "@/models/PwGroup";
import UserGroup from "./UserGroup";

export class User extends BaseModel {
  constructor({
    id = null,
    username,
    first_name,
    last_name,
    company = {},
    available_companies = [],
    email,
    pwgroups = [],
    active_language,
    phone,
    is_superuser = false,
    is_supportstaff = false,
    person,
    usergroups
  }) {
    super();

    this.id = id;
    this.username = username;
    this.first_name = first_name;
    this.last_name = last_name;
    this.company = company ? new Company(company) : null;
    this.available_companies = available_companies.map((c) => new Company(c));
    this.email = email;
    this.pwgroups = pwgroups.map((g) => new PwGroup(g));
    this.active_language = active_language;
    this.phone = phone;
    this.is_superuser = is_superuser;
    this.is_supportstaff = is_supportstaff;
    this.person = person;
    this.usergroups = usergroups ? usergroups.map((r) => new UserGroup(r)) : [];
  }

  get fullName() {
    return this.first_name + " " + this.last_name;
  }

  get isUser() {
    for (var i = 0; i < this.pwgroups.length; i++) {
      if (this.pwgroups[i].group.name == "user") {
        return true;
      }
    }
    return false;
  }

  get isAdmin() {
    for (var i = 0; i < this.pwgroups.length; i++) {
      if (this.pwgroups[i].group.name == "admin" || this.pwgroups[i].group.name == "company_admin") {
        return true;
      }
    }
    return false;
  }

  get isCompanyAdmin() {
    if (this.is_supportstaff) {
      return true
    }
    for (var i = 0; i < this.pwgroups.length; i++) {
      if (this.pwgroups[i].group.name == "company_admin") {
        return true;
      }
    }
    return false;
  }

  get isGuest() {
    for (var i = 0; i < this.pwgroups.length; i++) {
      if (this.pwgroups[i].group.name == "guest") {
        return true;
      }
    }
    return false;
  }

  get isSafetyRepresentative() {
    for (var i = 0; i < this.pwgroups.length; i++) {
      if (this.pwgroups[i].group.name == "safety_representative") {
        return true;
      }
    }
    return false;
  }

  hasPermission(permission) {
    if (this.is_supportstaff) {
      return true;
    } else {
      return this.pwgroups.some((pwg) => pwg.hasPermission(permission));
    }
  }

  hasModule(module) {
    return this.company.hasModule(module);
  }

  hasActivatedModule(module) {
    if (this.is_supportstaff) {
      return true
    }
    let prefix = "show_";

    // Loop through all roles assigned to the user
    return this.usergroups.some((r) => r[prefix + module]);
  }

  shallSeeModule(module) {
    return this.company.hasModule(module) && this.hasActivatedModule(module);
  }
}

export default User;
