import { QUESTION_INPUT_TYPES } from "@/constants";
import { BaseModel } from "@/models/BaseModel";
import { Condition } from "@/models/Condition";
import { FocusArea } from "@/models/FocusArea";
import { Option } from "@/models/Option";
import { Document } from "@/models/Document";
import { QUESTIONNAIRE_TYPES } from "../constants";
import i18n from "@/helpers/language";

export class Question extends BaseModel {
  constructor({
    id = null,
    text = null,
    info = null,
    info_id = null,
    is_required,
    required_value,
    input_type = QUESTION_INPUT_TYPES.TEXT,
    questionnaire_type = QUESTIONNAIRE_TYPES.SAFETY_INSPECTION,
    options = [],
    range_min,
    range_max,
    conditions = [],
    style_as_yes_no = false,
    enable_comments = true,
    focus_area = null,
    image = null,
    position,
    show_from_protocol_status,
    show_extended_info_value = null,
    followup_text,
    followup_protocol,
    followup_info,
    followup_activity,
    followup_questionnaire_type,
    followup_info_type,
    followup_info_name,
    followup_group,
    followup_generate
  }) {
    super();

    this.id = id;
    this.text = text;
    this.info = info;
    this.info_id = info_id;
    this.is_required = is_required;
    this.required_value = required_value;
    this.input_type = input_type;
    this.questionnaire_type = questionnaire_type;
    this.options = options.map((o) => new Option(o));
    this.range_min = range_min;
    this.range_max = range_max;
    this.conditions = conditions.map((c) => new Condition(c));
    this.style_as_yes_no = style_as_yes_no;
    this.enable_comments = enable_comments;
    this.focus_area = focus_area ? new FocusArea(focus_area) : null;
    this.image = image ? new Document(image) : null;
    this.position = position;
    this.show_from_protocol_status = show_from_protocol_status;
    this.show_extended_info_value = show_extended_info_value;
    this.followup_text = followup_text;
    this.followup_protocol = followup_protocol;
    this.followup_info = followup_info;
    this.followup_activity = followup_activity;
    this.followup_questionnaire_type = followup_questionnaire_type;
    this.followup_info_type = followup_info_type;
    this.followup_info_name = followup_info_name;
    this.followup_group = followup_group;
    this.followup_generate = followup_generate;
  }

  conditionsFulfilled(answers, protocol_status = 0) {
    return this.conditions.every((c) => {
      return answers.find(
        (a) =>
          a.value_option && a.question.id === c.condition_question.id && a.value_option.id === c.condition_option.id
      );
    }) &&
      this.show_from_protocol_status <= protocol_status;;
  }

  getCheckboxOption(state = true) {
    if (this.input_type !== QUESTION_INPUT_TYPES.CHECKBOX &&
      this.input_type !== QUESTION_INPUT_TYPES.CHECKLIST &&
      this.input_type !== QUESTION_INPUT_TYPES.FOLLOWUP_PROTOCOL2 &&
      this.input_type !== QUESTION_INPUT_TYPES.CHECKLIST) {
      throw "Cannot get checkbox option - question input type is not checkbox";
    }

    const identifier = state ? "TRUE" : "FALSE";
    const option = this.options.find((o) => o.identifier === identifier);

    if (!option) {
      throw `Found no checkbox option for question (ID: ${this.id}) '${this.text}' and state ${state}`;
    }

    return option;
  }

  get componentName() {
    let name;
    switch (this.input_type) {
      case QUESTION_INPUT_TYPES.TEXT:
        name = "text-question";
        break;
      case QUESTION_INPUT_TYPES.NUMBER:
        name = "number-question";
        break;
      case QUESTION_INPUT_TYPES.CHECKBOX:
        name = "checkbox-question";
        break;
      case QUESTION_INPUT_TYPES.HEADER:
        name = "header-question";
        break;
      case QUESTION_INPUT_TYPES.SELECT_LIST:
        name = "select-list-question";
        break;
      case QUESTION_INPUT_TYPES.RANGE:
        name = "range-question";
        break;
      case QUESTION_INPUT_TYPES.TEXTAREA:
        name = "textarea-question";
        break;
      case QUESTION_INPUT_TYPES.INFO:
        name = "info-question";
        break;
      case QUESTION_INPUT_TYPES.RADIO_BUTTON:
        name = "radio-button-question";
        break;
      case QUESTION_INPUT_TYPES.LARGE_TEXTAREA:
        name = "large-textarea-question";
        break;
      case QUESTION_INPUT_TYPES.DATE:
        name = "date-question";
        break;
      case QUESTION_INPUT_TYPES.SIGN:
        name = "sign-question";
        break;
      case QUESTION_INPUT_TYPES.IMAGE:
        name = "image-question";
        break;
      case QUESTION_INPUT_TYPES.FILES:
        name = "files-question";
        break;
      case QUESTION_INPUT_TYPES.SIGN_IMAGE:
        name = "sign-image-question";
        break;
      case QUESTION_INPUT_TYPES.RISK:
        name = "risk-question";
        break;
      case QUESTION_INPUT_TYPES.FIRE:
        name = "fire-question";
        break;
      case QUESTION_INPUT_TYPES.REPORT:
        name = "report-question";
        break;
      case QUESTION_INPUT_TYPES.RISK2:
        name = "risk2-question";
        break;
      case QUESTION_INPUT_TYPES.RISK3:
        name = "risk3-question";
        break;
      case QUESTION_INPUT_TYPES.ROUND:
        name = "round-question";
        break;
      case QUESTION_INPUT_TYPES.FOLLOWUP_TEXT:
        name = "followup-text-question";
        break;
      case QUESTION_INPUT_TYPES.FOLLOWUP_PROTOCOL:
        name = "followup-protocol-question";
        break;
      case QUESTION_INPUT_TYPES.FOLLOWUP_INFO:
        name = "followup-info-question";
        break;
      case QUESTION_INPUT_TYPES.FOLLOWUP_ACTIVITY:
        name = "followup-activity-question";
        break;
      case QUESTION_INPUT_TYPES.FOLLOWUP_QUESTIONNAIRE:
        name = "followup-questionnaire-question";
        break;
      case QUESTION_INPUT_TYPES.FOLLOWUP_PROTOCOL2:
        name = "followup-protocol2-question";
        break;
      case QUESTION_INPUT_TYPES.CHECKLIST:
        name = "checklist-question";
        break;
    }
    return name;
  }

  isText() {
    return this.input_type === QUESTION_INPUT_TYPES.TEXT;
  }

  isNumber() {
    return this.input_type === QUESTION_INPUT_TYPES.NUMBER;
  }

  isCheckbox() {
    return this.input_type === QUESTION_INPUT_TYPES.CHECKBOX;
  }

  isHeader() {
    return this.input_type === QUESTION_INPUT_TYPES.HEADER;
  }

  isSelectList() {
    return this.input_type === QUESTION_INPUT_TYPES.SELECT_LIST;
  }

  isRadioButton() {
    return this.input_type === QUESTION_INPUT_TYPES.RADIO_BUTTON;
  }

  isRange() {
    return this.input_type === QUESTION_INPUT_TYPES.RANGE;
  }

  isTextarea() {
    return this.input_type === QUESTION_INPUT_TYPES.TEXTAREA;
  }

  isInfo() {
    return this.input_type === QUESTION_INPUT_TYPES.INFO;
  }

  isLargeTextArea() {
    return this.input_type === QUESTION_INPUT_TYPES.LARGE_TEXTAREA;
  }

  isDate() {
    return this.input_type === QUESTION_INPUT_TYPES.DATE;
  }

  isSign() {
    return this.input_type === QUESTION_INPUT_TYPES.SIGN;
  }

  isImage() {
    return this.input_type === QUESTION_INPUT_TYPES.IMAGE;
  }

  isFiles() {
    return this.input_type === QUESTION_INPUT_TYPES.FILES;
  }

  isSign2() {
    return this.input_type === QUESTION_INPUT_TYPES.SIGN_IMAGE;
  }

  isRisk() {
    return this.input_type === QUESTION_INPUT_TYPES.RISK;
  }

  isFire() {
    return this.input_type === QUESTION_INPUT_TYPES.FIRE;
  }

  isReport() {
    return this.input_type === QUESTION_INPUT_TYPES.REPORT;
  }

  isRisk2() {
    return this.input_type === QUESTION_INPUT_TYPES.RISK2;
  }

  isRisk3() {
    return this.input_type === QUESTION_INPUT_TYPES.RISK3;
  }

  isRound() {
    return this.input_type === QUESTION_INPUT_TYPES.ROUND;
  }

  isFollowupText() {
    return this.input_type === QUESTION_INPUT_TYPES.FOLLOWUP_TEXT;
  }

  isFollowupProtocol() {
    return this.input_type === QUESTION_INPUT_TYPES.FOLLOWUP_PROTOCOL;
  }

  isFollowupInfo() {
    return this.input_type === QUESTION_INPUT_TYPES.FOLLOWUP_INFO;
  }

  isFollowupActivity() {
    return this.input_type === QUESTION_INPUT_TYPES.FOLLOWUP_ACTIVITY;
  }

  isFollowupQuestionnaire() {
    return this.input_type === QUESTION_INPUT_TYPES.FOLLOWUP_QUESTIONNAIRE;
  }

  isFollowupProtocol2() {
    return this.input_type === QUESTION_INPUT_TYPES.FOLLOWUP_PROTOCOL2;
  }

  isChecklist() {
    return this.input_type === QUESTION_INPUT_TYPES.CHECKLIST;
  }

  isCommentable() {
    return !this.isHeader() && !this.isInfo() && !this.isReport();
  }

  hasOptions() {
    return this.isCheckbox() || this.isSelectList() || this.isRadioButton();
  }

  setDefaultOptions() {
    if (this.options.length == 0 && (this.input_type === QUESTION_INPUT_TYPES.CHECKBOX || this.input_type === QUESTION_INPUT_TYPES.CHECKLIST)) {
      let options = [
        new Option({ text: i18n.tc("ok"), identifier: "TRUE" }),
        new Option({ text: i18n.tc("notOk"), identifier: "FALSE" })
      ];
      this.options = options;
    }
  }

  maxPoints() {
    var maxpoints = 0;
    if (this.hasOptions()) {
      for (var i = 0; i < this.options.length; i++) {
        if (((this.required_value == null) || (this.required_value == this.options[i].points)) && this.options[i].points > maxpoints) {
          maxpoints = this.options[i].points;
        }
      }
    }
    else if (this.range_min != null && this.range_max != null) {
      maxpoints = this.range_max;
    }

    return maxpoints;
  }
}

export default Question;
